<template>
    <div>
        <v-img height="5em" :src="require('@/assets/images/avatars/profil.svg')" contain alt="Connect and socialize">
        </v-img>
        <!-- title -->
        <v-card-title class="d-flex align-center justify-center mb-2">
            <h3 class="font-weight-bolder" v-if="headata.title">
                <!-- Password recovery -->
                {{headata.title}}
            </h3>
        </v-card-title>
        <!-- <v-card-subtitle class="text-center mb-1">
            {{headata.title}}
        </v-card-subtitle> -->
        
        <v-card-subtitle class="text-center mb-2" v-if="headata.subtitle">
            {{headata.subtitle}}
        </v-card-subtitle>
    </div>
</template>
<script>
export default {
    props: {
        headata: {
            type: Object,
            default: () => {}
        }
    }
}
</script>