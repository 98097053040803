<template>
    <v-container class="my-10">
        <v-row align="center" justify="center">
            <v-col cols="8" md="6" class="center">
                <v-stepper v-model="this.e1" elevation="0" class="transparent pa-0">
                    <v-stepper-header class="stepperitem  mb-4">
                        <v-stepper-step :complete="this.e1 > 1" step="1">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <!-- <v-stepper-step step="">
                            </v-stepper-step>
                            <v-divider></v-divider> -->
                        <v-stepper-step :complete="this.e1 > 2" step="2">
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card class="px-8 py-8 mx-auto">
                                <recov-header :headata="headerdata.mailcode"></recov-header>
                                <v-card-text>
                                    <v-form ref="forms1">
                                        <v-text-field v-model="email" outlined dense label="Email" type="email"
                                            placeholder="john@example.com" @input="$v.email.$touch()"
                                            @blur="$v.email.$touch()" :hide-details="emailErrors.isvalid"
                                            :error-messages="emailErrors.value" clearable class="mb-3"
                                            v-if="isResetWithEmail">
                                        </v-text-field>

                                        <v-text-field v-model="phonenumber" readonly :rules="phoneRules" outlined dense
                                            type="numeric" label="Phone number" placeholder="655 32 03 69"
                                            @input="$v.phonenumber.$touch()" @blur="$v.phonenumber.$touch()"
                                            :hide-details="phonenumber.isvalid"
                                            :error-messages="phonenumberErrors.value" clearable class="mb-3" v-else>
                                        </v-text-field>

                                        <div class="d-flex align-center justify-space-between flex-wrap mt-2">
                                            <v-btn block color="primary" @click="sendActivationCodeSubmit" class="px-10"
                                                :loading="loader.activation ? true : false">
                                                Send activation code
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </v-card-text>
                                <!-- divider -->

                                <v-card-text class="d-flex align-center mt-2">
                                    <v-divider></v-divider>
                                    <span class="mx-5">or</span>
                                    <v-divider></v-divider>
                                </v-card-text>
                                <v-card-text class="d-flex align-center justify-center flex-wrap mt-1">
                                    <span>
                                        Reset password with
                                    </span>
                                    <v-btn color="primary" text @click="resetwithphone()"
                                        class="text-transform-none ml-1">
                                        {{ isResetWithEmail ? 'phone number' : 'email' }}
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card class="px-8 py-8 mx-auto">
                                <recov-header :headata="headerdata.verifcode"></recov-header>
                                <!-- form input -->
                                <v-card-text>
                                    <v-form ref="forms2" v-if="this.e1 >= 2">
                                        <v-text-field v-model="activcode" outlined dense label="Activation code"
                                            type="text" placeholder="Enter activation code"
                                            :error-messages="activcodeErrors.value"
                                            :hide-details="activcodeErrors.isvalid" @input="$v.activcode.$touch()"
                                            @blur="$v.activcode.$touch()" clearable class="mb-3">
                                        </v-text-field>
                                        <v-text-field v-model="password" outlined dense
                                            :type="isPasswordVisible ? 'text' : 'password'" label="New password"
                                            placeholder="············"
                                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                            @click:append="isPasswordVisible = !isPasswordVisible" clearable
                                            :hide-details="passwordErrors.isvalid"
                                            :error-messages="passwordErrors.value" @input="$v.password.$touch()"
                                            @blur="$v.password.$touch()" class="mb-3">
                                        </v-text-field>

                                        <v-text-field v-model="confpassword" outlined dense
                                            :type="isConfPasswordVisible ? 'text' : 'password'" label="Confirm password"
                                            placeholder="············"
                                            :append-icon="isConfPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                            clearable :hide-details="confirmPassErrors.isvalid"
                                            :error-messages="confirmPassErrors.value"
                                            @click:append="isConfPasswordVisible = !isConfPasswordVisible">
                                        </v-text-field>
                                        <div class="d-flex align-center justify-space-between flex-wrap mt-5">
                                            <v-btn block color="primary" @click="resetPasswordWithCode" class="px-10"
                                                :loading="this.loader.reset ? true : false">
                                                Reset password
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import RecovHeader from './RecovHeader.vue'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        email: { required, email },
        password: { required, minLength: minLength(6), maxLength: maxLength(15) },
        phonenumber: { required, minLength: minLength(8) },
        activcode: { required }
    },
    components: {
        RecovHeader,
    },
    data() {
        return {
            loader: { activation: false, reset: false },
            e1: 1,
            isResetWithEmail: true
        }
    },
    computed: {
        activcodeErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.activcode.$dirty) return errors
            !this.$v.activcode.required && errors.value.push('Activation code required to reset password') && (errors.isvalid = false)
            return errors
        },
        emailErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.value.push('Must be valid e-mail') && (errors.isvalid = false)
            !this.$v.email.required && errors.value.push('E-mail is required') && (errors.isvalid = false)
            return errors
        },
        passwordErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.minLength && errors.value.push('Password must be at most 6 characters long') && (errors.isvalid = false)
            !this.$v.password.required && errors.value.push('Password is required') && (errors.isvalid = false)
            return errors
        },
        confirmPassErrors() {
            const errors = { isvalid: true, value: [] }
            if (this.password === this.confpassword) {
                errors.isvalid = true
            } else {
                errors.value.push('Password must be the same')
                errors.isvalid = false
            }
            return errors
        },
        phonenumberErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.phonenumber.$dirty) return errors
            !this.$v.phonenumber.minLength && errors.value.push('Phone number must be at most 8 characters long') && (errors.isvalid = false)
            !this.$v.phonenumber.required && errors.value.push('Password is required') && (errors.isvalid = false)
            return errors
        }
    },

    methods: {
        toNextStep(stp) {
            if (stp === 1)
                this.e1 = 1
            if (stp === 2)
                this.e1 = 2
        },

        // step 1: Send activation code
        resetwithphone() {
            this.isResetWithEmail = !this.isResetWithEmail
        },
        checkValidationForm(stape) {
            if (stape === 'code') {
                if (this.emailErrors.isvalid) { return true } else { return false }
            }
            if (stape === 'reset') {
                if (
                    this.passwordErrors.isvalid && this.confirmPassErrors.isvalid) {
                    return true
                } else { return false }
            }
        },

        sendActivationCodeSubmit() {
            var form = new FormData();
            form.append("user_form[login]", this.isResetWithEmail === true ? this.email : this.phonenumber);
            if (this.isResetWithEmail === false) { form.append("user_form[country_iso]", "CM") }

            if (this.checkValidationForm('code')) {
                this.loader.activation = true;
                Drequest.api("user.initresetpassword")
                    .data(form)
                    .post((response) => {
                        if (response.success === true) {
                            this.$store.commit("setUserId", response.user.id);
                            this.$fire({
                                type: "success",
                                text: response.detail,
                                timer: 3000
                            })
                            this.loader.activation = false;
                            this.toNextStep(2)
                        }
                        else {
                            this.$fire({
                                type: "error",
                                text: response.error,
                                timer: 3000
                            })
                            this.loader.activation = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.loader.activation = false;
                    }
                    );
            }
        },
        // Step 2: verify activation code
        resetPasswordWithCode() {
            var formreset = new FormData();
            formreset.append("activationcode", this.activcode);
            formreset.append("password", this.password);
            this.loader.reset = true;
            if (this.checkValidationForm('reset')) {
                Drequest.api(`user.resetpassword?user_id=${this.$store.getters.getUser.id}`)
                    .data(formreset)
                    .post((response) => {
                        this.loader.reset = false;
                        if (response.success === true) {
                            alert(response.detail)
                            this.$router.push({ name: 'login' });
                        }
                        else {
                            // console.log(response.values.log)
                            alert(response.error)
                        }
                    })
                    .catch((err) => {
                        console.log(err + ' Erreur de reinitialisation..!')

                        this.loader.reset = false;
                    });
            }
        },
    },
    setup() {
        var email = ref('')
        var phonenumber = ref('')
        //  Stepper 2: verifcation of activation code
        var activcode = ref('')
        const isPasswordVisible = ref(false)
        const isConfPasswordVisible = ref(false)
        var confpassword = ref('')
        var password = ref('')
        let headerdata = {
            mailcode: {
                title: "Passeword recovery",
                subtitle: "Enter your user account's verified email adress and we will send you a password reset code",
            },
            verifcode: {
                title: "Passeword recovery",
                subtitle: "We have sent a recovery code to your email address"
            },
            passcode: {
                title: "Passeword recovery",
                subtitle: "Change your password"
            }
        }
        const icons = { mdiEyeOutline, mdiEyeOffOutline }
        return {
            icons, headerdata,
            // Step1 
            email, phonenumber,
            // 
            activcode,
            isConfPasswordVisible, isPasswordVisible,
            password, confpassword,
        }
    },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

//@override
.v-card {
    border-radius: 1em !important;
}

h1 {
    margin: 0 !important;
}
</style>
